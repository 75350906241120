<template>
    <v-select
        class=""
        v-model="selectedFilterStatus"
        :label="label"
        :items="filterStatus"
        item-text="name"
        item-value="slug"
        :multiple="multiple"
        chips
        :data-cy="dataCy"
    ></v-select>
  
  </template>
  
  <script>
  export default {
    name: "QCNotificationsStatusFilter",
    model: {
        prop: 'model',
        event: 'input'
    },
    props: {
      dataCy: {
        default: ''
      },
      model: {
        type: [String, Array],
        default: () => [null]       
      },
      filterLabel: String,
      multiple: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        selectedFilterStatus: this.value,
        filterStatus: [
          {
            name: '',
            slug: 'submitted'
          },
          {
            name: '',
            slug: 'approved'
          },
          {
            name: '',
            slug: 'invoiced'
          },
          {
            name: '',
            slug: 'deleted'
          }
        ],
      }
    },
    watch: {
      selectedFilterStatus: function (val) {
            this.$emit('input', val);
      },
      model: function(val) {
          this.selectedFilterStatus = val;
      }
    },
    created() {
      let me = this;
      this.filterStatus.forEach(function (value) {
        value.name = me.$t('qc-notifications.data.status.' + value.slug);
      });
    },
    computed: {
        label() {
            return this.filterLabel && this.filterLabel !== '' ? this.filterLabel : this.$t('components.qc-notifications-filters.status-filter.label')
        }
    },
  }
  </script>
  
  <style scoped>
  </style>
  
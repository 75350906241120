<template>
  <div>
    <BasicEditContainer
      ref="editor"
      :data-loaded="dataLoaded"
      v-if="dataLoaded"
      :title="t('title')"
      @cancel="cancel"
      :allow-delete="false"
      :object-type="$t('expenses.data.object-type')"
      :object-name="item.title"
      :has-changes="false"
      >
        <template v-slot:default>
          <v-card elevation="0">
            <v-card-title>{{ t('details.group-title') }}</v-card-title>
            <v-card-text>
              <div>
                <div class="disabled-input">
                  <label>{{ t('details.title') }}</label>
                  <span>{{ item.title }}</span>
                </div>

                <div class="disabled-input">
                  <label>{{ t('details.description') }}</label>
                  <span>{{ item.description }}</span>
                </div>

                <div class="disabled-input">
                  <label>{{ t('details.expense-type') }}</label>
                  <span v-if="item.name_code">{{ $t('expenses.data.type.' + item.name_code) }}</span>
                  <span v-if="!item.name_code">{{ item.name }}</span>
                </div>

                <div class="disabled-input">
                  <label>{{ t('details.date') }}</label>
                  <span>{{ formatDate(item.date) }}</span>
                </div>

                <div v-if="item.type.calculation_type_code === 'AT_COST'" class="disabled-input">
                  <label>{{ t('details.amount_incl_vat') }}</label>
                  <span>{{ item.total_amount_incl_vat }} {{ item.currency }}</span>
                </div>

                <div v-if="item.type.calculation_type_code === 'HOURLY_RATE'" class="disabled-input">
                  <label>{{ t('details.hours') }}</label>
                  <span>{{ item.hours }} {{ t('hours') }}</span>
                </div>

                <div v-if="item.type.calculation_type_code === 'FACTOR'" class="disabled-input">
                  <label>{{ t('details.factor') }}</label>
                  <span>{{ item.quantity }} {{ item.unit }}</span>
                </div>

                <div class="disabled-input">
                  <label>{{ t('details.employee') }}</label>
                  <span>{{ item.user.display_name }}</span>
                </div>
              </div>
            </v-card-text>
          </v-card>

          <v-card elevation="0" class="sp-mar-top-3">
            <v-card-title>{{ t('comment.group-title') }}</v-card-title>
            <v-card-text>
              <v-textarea
                v-if="!hasApprovalStatus"
                solo
                name="comment"
                v-model="approvalComment"
                :label="t('comment.hint')"
              ></v-textarea>

              <span v-if="hasApprovalStatus">
                  {{ item.approval_comment }}
                </span>
            </v-card-text>
          </v-card>

          <v-card v-if="item.is_reimbursed" elevation="0" class="sp-mar-top-3">
            <v-card-title>{{ t('reimburse.group-title') }}</v-card-title>
            <v-card-text>
              <div class="disabled-input">
                <label>{{ t('reimburse.amount') }}</label>
                <span>{{ item.reimbursement_amount }} {{ item.reimbursement_currency }}</span>
              </div>

              <div class="disabled-input" v-if="item.status === 'reimbursed'">
                <label>{{ t('reimburse.date') }}</label>
                <span>{{ formatDate(item.reimbursement_date) }}</span>
              </div>

              <div class="disabled-input" v-if="item.status === 'reimbursed'">
                <label>{{ t('reimburse.comment') }}</label>
                <span>{{ item.reimbursement_comment }}</span>
              </div>

              <div class="disabled-input" v-if="item.status === 'reimbursed'">
                <label>{{ t('reimburse.approved_by') }}</label>
                <span>{{ item.reimbursement_user.displayName }}</span>
              </div>
            </v-card-text>
          </v-card>
        </template>
      
        <template v-slot:meta>
          <v-card elevation="0" v-if="item.documents" class="sp-mar-bottom-3">
            <v-card-title>{{ t('picture.group-title') }}</v-card-title>
            <v-card-text class="expense-image-wrapper">
              <div v-for="(image, index) in imageData" :key="index" class="expense-image">
                <v-img :src="image"></v-img>
                <v-btn
                  color="primary"
                  elevation="0"
                  outlined
                  class="icon-left"
                  @click="downloadImage(index)"
                >
                  <v-icon>mdi-download</v-icon>
                  {{ t('picture.download') }}
                </v-btn>
              </div>
            </v-card-text>
          </v-card>

          <v-card elevation="0">
            <v-card-title>{{ t('history.group-title') }}</v-card-title>
            <v-card-text>
              <div class="disabled-input">
                <label>{{ t('history.created_at') }}</label>
                <span>{{ formatDateTime(item.created_at) }}</span>
              </div>

              <div class="disabled-input">
                <label>{{ t('history.status') }}</label>
                <span>{{ $t('expenses.data.status.' + item.status) }}</span>
              </div>

              <div class="disabled-input" v-if="hasApprovalStatus">
                <label v-if="item.status === 'approved'">{{ t('history.approved_at') }}</label>
                <label v-if="item.status === 'rejected'">{{ t('history.rejected_at') }}</label>
                <span>{{ formatDateTime(item.approval_date) }}</span>
              </div>

              <div class="disabled-input" v-if="hasApprovalStatus">
                <label v-if="item.status === 'approved'">{{ t('history.approved_by') }}</label>
                <label v-if="item.status === 'rejected'">{{ t('history.rejected_by') }}</label>
                <span>{{ item.approved_by.display_name }}</span>
              </div>
            </v-card-text>
          </v-card>

        </template>

        <template v-slot:action-buttons>
          <v-btn
            elevation="0"
            class="icon-left"
            @click="reject()"
            v-if="canEdit"
          >
            <v-icon>mdi-check</v-icon>
            {{ t('reject') }}
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            class="icon-left"
            @click="approve()"
            v-if="canEdit"
          >
            <v-icon>mdi-check</v-icon>
            {{ t('approve') }}
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            class="icon-left"
            @click="setReimbursed()"
            v-if="item.can_reimburse"
          >
            <v-icon>mdi-check</v-icon>
            {{ t('reimburse-action') }}
          </v-btn>
        </template>
      </BasicEditContainer>

      <v-dialog
        v-model="dialogs.reimburse.show"
        width="440"
        persistent
      >
        <expense-edit-reimbursement
          v-if="dataLoaded"
          @cancel="dialogs.reimburse.show = false"
          @save="saveReimburse"
          :initial-amount="dialogs.reimburse.amount"
          :reimbursement-currency="item.reimbursement_currency"
        />
      </v-dialog>
  </div>
</template>

<script>
import { HTTP } from '@/auth'
import loading from '@/components/layouts/Loading'
import toolbar from '@/components/layouts/Navigation'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import CancelButtonDialog from '@/components/layouts/CancelButtonDialog'
import { Buffer } from 'buffer'
import BasicEditContainer from '@/components/inputs/BasicEditContainer'
import moment from 'moment/moment'
import ExpenseEditReimbursement from '@/views/ExpenseEditReimbursment'

export default {
  name: 'ExpenseEdit',
  components: {
    ExpenseEditReimbursement,
    BasicEditContainer,
    CancelButtonDialog,
    loading,
    toolbar,
    DatePicker
  },
  data() {
    return {
      itemId: null,
      item: null,
      dataLoaded: false,
      approvalComment: '',
      errorLoading: false,
      imageData: null,
      dialogs: {
        reimburse: {
          show: false,
          amount: '',
        }
      }
    }
  },
  created() {
    this.itemId = this.$route.params.id
    this.loadData()
  },
  computed: {
    canEdit() {
      return this.item && this.item.status === 'filed'
    },
    hasApprovalStatus() {
      return this.item && (this.item.status === 'approved' || this.item.status === 'rejected')
    },
  },
  methods: {
    getUserConfirmResponse(response) {
      this.cancelConfirmed = response
    },
    t: function (key) {
      return this.$t('expenses.detail.' + key)
    },
    async loadData() {
      try {
        let response = await HTTP.get('expenses/' + this.itemId)
        this.item = response.data.data
        this.dataLoaded = true
        this.loadDocumentImage()
      } catch (err) {
        this.$root.infoNotification.showError(err.message)
        this.errorLoading = true
        this.dataLoaded = false
      }
    },
    loadDocumentImage() {
      if (this.item.documents) {
        this.imageData = []
        let documents = this.item.documents.split(';')
        documents.forEach(doc => {
          HTTP.get('/expense-documents/' + doc, { responseType: 'arraybuffer' }).then(function (response) {
            this.imageData.push('data:image/jpeg;base64,' + new Buffer(response.data, 'binary').toString('base64'))
          }.bind(this))
        })
      }
    },
    async approve() {
      await this.doAction('approve')
      this.$root.infoNotification.showMessage(this.t('approved-confirm-message'))
    },
    async reject() {
      await this.doAction('reject')
      this.$root.infoNotification.showMessage(this.t('reject-confirm-message'))
    },
    async doAction(action) {
      const json = {
        ids: [this.itemId],
        comment: this.approvalComment
      }
      await HTTP.post('expense-' + action, json)
      this.cancel()
    },
    cancel() {
      this.$router.push({ name: 'Expenses' })
    },
    downloadImage(index) {
      if (typeof this.imageData[index] === 'undefined') {
        return
      }
      let fileName = "ExpenseDocument_"
          + moment(this.item.created_at).format('YYYY_MM_DD') + "_"
          + this.item.user.firstname + "_"
          + this.item.user.lastname

      const downloadLink = document.createElement("a")
      downloadLink.href = this.imageData[index]
      downloadLink.download = fileName
      downloadLink.click()
    },
    setReimbursed() {
      this.dialogs.reimburse.amount = this.item.reimburse_amount
      this.dialogs.reimburse.show = true
    },
    async saveReimburse(data) {
      data.id = this.itemId
      await HTTP.post('expense-reimburse', data)
      await this.loadData()
      this.dialogs.reimburse.show = false
    }
  }
}
</script>

<style lang="scss" scoped>
.full-width {
  width: 100%;
}

.md-avatar.rectangle {
  margin: 0;
  border-radius: 0;
}

.md-theme-default.md-input-disabled {
  color: rgba(0, 0, 0, .38);
}

.mx-datepicker {
  margin-top: 0;
  margin-bottom: 0;
  max-width: 100%;
}

.disabled-input {
  margin-bottom: 24px;
  font-size: 16px;

  label {
    line-height: 24px;
    display: block;
    color: rgba(0, 0, 0, 0.54);
  }

  span {
    display: block;

    &.small {
      font-size: 14px;
    }
  }
}

hr {
  border: 0;
  border-top: 1px solid rgba(227, 227, 227, 0.7);
  margin: 16px 0;
}

.time-label {
  line-height: 24px;
  align-self: center;
}

.time-string {
  margin-left: 16px;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  background: #f7f7f7;
  padding: 16px;
  border: 1px solid rgba(227, 227, 227, 0.7);
  border-radius: 4px;
}

.sp-flex-1 {
  max-width: 100%;
}

.expense-image-wrapper {
  display: flex;
  flex-direction: column;

  & > .layout {
    margin-top: 0;
  }

  .expense-image {
    position: relative;
    max-width: 100%;

    & + .expense-image {
      margin-top: 16px;
    }

    .v-btn {
      position: absolute;
      bottom: 16px;
      left: 16px;
      background: #FFF;
    }
  }
}
</style>

